/*.App {*/
/*text-align: center;*/
/*}*/
body {
    font-family: 'Roboto', sans-serif;
    color: #45494a;
}

.loginContainer {
    background: whitesmoke;
    opacity: .8;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.chartsContainer {
    background: white;
    opacity: 0.8;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.leaflet-container {
    height: 49vh;
    width: 100%;
    z-index: 0;
}


hr {
    border: none;
    height: 2px;
    /* Set the hr color */
    /*color: #333; !* old IE *!*/
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    background-color: #76cdff; /* Modern Browsers */
}

/*li {*/
/*    font-size: 14px;*/
/*    margin-left: 10px;*/
/*    list-style-type: square;*/
/*}*/

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 40vmin;*/
/*}*/

/*.App-header {*/
/*background-color: #282c34;*/
/*min-height: 100vh;*/
/*display: flex;*/
/*flex-direction: column;*/
/*align-items: center;*/
/*justify-content: center;*/
/*font-size: calc(10px + 2vmin);*/
/*color: white;*/
/*}*/

/*.App-link {*/
/*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*from {*/
/*transform: rotate(0deg);*/
/*}*/
/*to {*/
/*transform: rotate(360deg);*/
/*}*/
/*}*/
