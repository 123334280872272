/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
    /*monospace;*/
/*}*/

/*.App {*/
/*text-align: center;*/
/*}*/
body {
    font-family: 'Roboto', sans-serif;
    color: #45494a;
}

.loginContainer {
    background: whitesmoke;
    opacity: .8;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.chartsContainer {
    background: white;
    opacity: 0.8;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.leaflet-container {
    height: 49vh;
    width: 100%;
    z-index: 0;
}


hr {
    border: none;
    height: 2px;
    /* Set the hr color */
    /*color: #333; !* old IE *!*/
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    background-color: #76cdff; /* Modern Browsers */
}

/*li {*/
/*    font-size: 14px;*/
/*    margin-left: 10px;*/
/*    list-style-type: square;*/
/*}*/

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 40vmin;*/
/*}*/

/*.App-header {*/
/*background-color: #282c34;*/
/*min-height: 100vh;*/
/*display: flex;*/
/*flex-direction: column;*/
/*align-items: center;*/
/*justify-content: center;*/
/*font-size: calc(10px + 2vmin);*/
/*color: white;*/
/*}*/

/*.App-link {*/
/*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*from {*/
/*transform: rotate(0deg);*/
/*}*/
/*to {*/
/*transform: rotate(360deg);*/
/*}*/
/*}*/

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}
